/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { inject, observer } from "mobx-react"

import Header from "./header"
import "./layout.css"

const Layout = ({
  children,
  handleCartClick,
  hideNav,
  hideSubNav,
  fullWidth,
  homepage,
  cart,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    const localStorageCart = cart.getFromLocalStorage('cart')
    if (localStorageCart) {
      cart.setCart(localStorageCart)
    }
  },[])

  return (
    <>
      <Header
        handleCartClick={handleCartClick}
        siteTitle={data.site.siteMetadata?.title || `Title`}
        hideNav={hideNav}
        hideSubNav={hideSubNav}
        homepage={homepage}
      />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: fullWidth ? "100%" : 1162,
          padding: fullWidth ? 0 : `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer></footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default inject("cart")(observer(Layout))
