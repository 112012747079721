import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import ShoppingCart from "@material-ui/icons/ShoppingCartOutlined"
import { inject, observer } from "mobx-react"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Hide from "../components/hide"
import MenuIcon from "@material-ui/icons/Menu"
import Box from "@material-ui/core/Box"
import { Divider } from "@material-ui/core"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll"

const HeaderBar = styled.header`
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
`
const SubHeaderBar = styled.div`
  display: flex;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  width: 100%;
  background-color: rgb(247, 247, 247);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  position: absolute;
  top: 80px;
`

const Message = styled.div`
  text-transform: uppercase;
  font-size: 12px;
`

const CTA = styled.a`
  font-size: 12px;
  margin-left: 10px;
  text-decoration: underline;
  color: #0084ff;
  cursor: pointer;
`

const HeaderContainer = styled.div`
  max-width: 1160px;
  padding: 16px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LogoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 300px;

  > img {
    margin-bottom: 0;
    height: 40px;
  }
`

const CartCount = styled.div`
  width: 15px;
  height: 15px;
  font-family: "Poppins", sans-serif;
  background: #f35b5b;
  color: white;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  position: absolute;
  border-radius: 50px;
  top: 0px;
  right: -5px;
`

const CartContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  svg {
    color: black;
    min-height: 40px;
  }
`

const Nav = styled.div`
  display: flex;
  font-size: 15px;
  line-height: 40px;
  a {
    font-weight: 500;
    margin-right: 26px;
    cursor: pointer;
    color: #686868;
  }
`

const SlideContainer = styled.div`
  display: flex;
`
const RightContainer = styled.div`
  display: flex;
`

const MobileNavContainer = styled.div`
  width: 250px;
  a {
    color: #6c6e71;
  }
`

const OrderButton = styled.div`
  width: 100%;
  background: #ff6622;
  text-transform: uppercase;
  font: 500 16px/40px "Poppins", sans-serif;
  padding: 0 35px;
  border-radius: 30px;
  text-align: center;
  display: flex;
  margin-right: 16px;

  a {
    color: #fff;
    margin: 0;
  }
  border: none;
`
const NavLinks = [
  {
    label: "Home",
    url: "/",
    section: "home",
  },
  {
    label: "Reviews",
    url: "/#reviews",
    section: "reviews",
  },
  {
    label: "Faqs",
    url: "/#faq",
    section: "faqs",
  },
]

const Header = ({ cart: cartStore, hideNav, homepage }) => {
  const { cartCount } = cartStore
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(!isDrawerOpen)}
        onOpen={() => setIsDrawerOpen(!isDrawerOpen)}
      >
        <MobileNavContainer>
          <List>
            <ListItem>
              <LogoContainer>
                <img alt="light-pole-defender-logo" src={`/lock-logo.png`} />
              </LogoContainer>
              <Divider orientation="horizontal" variant="fullWidth" light />
            </ListItem>
            {NavLinks.map(link => (
              <>
                <ListItem>
                  {homepage ? (
                    <ScrollLink
                      aria-current={link.label}
                      activeClass="active"
                      to={link.section}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      {link.label}
                    </ScrollLink>
                  ) : (
                    <Link aria-current={link.label} to={link.url}>
                      {link.label}
                    </Link>
                  )}
                </ListItem>
                <Divider orientation="horizontal" variant="fullWidth" light />
              </>
            ))}
            <ListItem>
              <Link to="/lpd-product-detail">Product</Link>
            </ListItem>
            <ListItem>
              <Link to="/articles">Articles</Link>
            </ListItem>
          </List>
        </MobileNavContainer>
      </SwipeableDrawer>
      <HeaderBar>
        <HeaderContainer>
          <Link to="/">
            <LogoContainer>
              <img alt="light-pole-defender-logo" src={`/lock-logo.png`} />
            </LogoContainer>
          </Link>
          <RightContainer>
            <Hide xs sm md>
              {!hideNav && (
                <Nav>
                  {NavLinks.map(link => (
                    <div>
                      {homepage ? (
                        <ScrollLink
                          activeClass="active"
                          to={link.section}
                          spy={true}
                          smooth={true}
                          offset={-70}
                          duration={500}
                        >
                          {link.label}
                        </ScrollLink>
                      ) : (
                        <Link to={link.url}>{link.label}</Link>
                      )}
                    </div>
                  ))}

                  <Link to="/lpd-product-detail">Product</Link>
                  <Link>
                    <Link to="/articles">Articles</Link>
                  </Link>
                  {homepage && (
                    <OrderButton>
                      <Link to="/lpd-product-detail">Order Now</Link>
                    </OrderButton>
                  )}
                </Nav>
              )}
            </Hide>
            <CartContainer>
              <Link aria-current="cart-page" to="/cart">
                <ShoppingCart />
                {cartCount > 0 && <CartCount> {cartCount} </CartCount>}
              </Link>
            </CartContainer>
            <Hide lg>
              <Box
                ml="8px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                css={{ cursor: "pointer" }}
              >
                <MenuIcon onClick={() => setIsDrawerOpen(!isDrawerOpen)} />
              </Box>
            </Hide>
          </RightContainer>
        </HeaderContainer>
      </HeaderBar>
      {/* {!hideSubNav && (
        <SubHeaderBar>
          <HeaderContainer>
            <SlideContainer>
              <Message>Are you a contractor? </Message>
              <CTA> Click here discount details</CTA>
            </SlideContainer>
          </HeaderContainer>
        </SubHeaderBar>
      )} */}
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default inject("cart")(observer(Header))
